import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addStudExamRec, addStudExamRecResultList } from '../models/examination';
import { BaseService } from './base.service';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
})
@Injectable({
  providedIn: 'root'
})
export class ExaminationService {


  constructor(private http: HttpClient, private base: BaseService) { }
  private baseUrl = this.base._baseUrl + "/examsrecord"


  addStudExamRec(payload: addStudExamRec): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/addupdate`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  uploadExcel(lecturer: number, level: string, program: string, semester: string, session: string, formData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/upload-excel?lecturer=${lecturer}&level=${level}&program=${program}&semester=${semester}&session=${session}`, formData)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  exportToExcel(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/students/export/excel`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  downloadStudentsRecordFile(course: number, deptid: number, lecturer: number, session: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/students/export/excel?courses=${course}&deptid=${deptid}&lecturer=${lecturer}&session=${session}`,
      { headers: headers, responseType: 'blob' as 'json' }
    )
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getResultByDeptLevelsemesterSession(dept: number, level: string, semester: string, session: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-studentresult-by-dept?dept=${dept}&level=${level}&semester=${semester}&session=${session}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
