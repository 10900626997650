import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addCarryOver, addEnterpreneur, addUpdateCourseReg } from '../models/staff';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CourseRegService {
  constructor(private http: HttpClient, private base: BaseService) { }

  private baseUrl = this.base._baseUrl + '/course-registration'

  addUpdateCourseReg(payload: addUpdateCourseReg): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/addUpdate`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addCarryOver(payload: addCarryOver): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add-carryover`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addEnterpreneur(payload: addEnterpreneur): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add-enterpreneur`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCoursesByStudentId(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/getCourse-by-student?student=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCoursesByStudentIdSemester(level: string, semester: string, session: string, studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/getCourse-by-student-semester?level=${level}&semester=${semester}&session=${session}&student=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
