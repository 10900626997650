export class Constants {
    static USER_ROLE: string = 'roletype'
    static LOGIN_USER: string = 'logStudent';
    static PAGE: number = 0
    static PAGE_SIZE: number = 100_000
}

export class MessageUtil {
    static INFO_TITLE = "Please Wait!!!";
    static PROCESS_SAVING = "Saving...,  Please Wait!!!";
    static PROCESS_DELETING = "Deleting...,  Please Wait!!!";
    static PROCESS_DOWNLOAD = "Downloading,  Please Wait!!!";
    static PROCESS_UPLOAD = "Uploading,  Please Wait!!!";

    static TRANSACTION_INPROGRESS: string = "Please wait!, Transaction is in Processing";
    static TRANSACTION_SUCCESS: string = "Your Transaction is Successful";
    static TRANSACTION_ERROR: string = "Ooops!, Your Transaction is Failed";
    static TRANSACTION_CANCEL: string = "Ooops!, Your Transaction is Cancel";
    static TRANSACTION_MODAL_WARNING:string = "Transaction Cannot be process, Please fill all the filed";

    static MODAL_SUCCESS:string = "Completed";
    static MODAL_DELETE:string = "Are you sure you want to delete? "
    static FILL_FORM_ERROR:string = "Please Fill all the Required FIELD"
    
    static INVALID_LOGIN: string = "Either username/password not correct";
    static INVALID_FORM: string = "Please fill out the required fields";
    static INTERNAL_SERVER_ERROR: string = "Oops! We encountered an unexpected problem";
    static PASSWORD_NOT_MATCHED: string = "Password not matched Confirmed Password";
    static TIMEOUT_DURATION: number = 4000;
    static SAVE_NOT_DONE: string = "** Could not save, Retry";
    static RECORD_NOT_FOUND: string = "** Could not retrieve qualification types";
    static ERROR_SAVING_INFO: string = "** Could not save personal information, Retry";
    static UPLOAD_ERROR: string = "Error! Uploading File";
    static RESPONSE_SUCCESS: string = "Success";
    static SERVER_ERROR: string = 'Oops! We encountered an unexpected problem'
    static PASSWORD_INCORRECT: string = "Incorrect Password ";

    static LOGIN_SUCCESS:string="Login Successfull";
    static SUCCESS:string="Success";
    static RETRIEVE_ERROR:string="Oops! No record found";
    static ERROR_REASON:string="Oops! We encountered an unexpected problem";
    static PERIOD_ERROR:string="Oops! We encountered an unexpected problem";
    static PASSWORD_UPDATED:string = "Your Password has been updated Successfully";
    
}