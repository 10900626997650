import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  baseUrl : string = this.base._baseUrl + '/dashboard'

  constructor(private http: HttpClient, private base : BaseService) { }

  getExaminerDashboard(admissionYear: string, semester: string): Observable<any> { 
    return this.http.get<any>(this.baseUrl +         
      `/examner-data?admissionYear=${admissionYear}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
