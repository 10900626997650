import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { resetPassword } from '../models/admin';
import { addStudent, addExaminationObt, institutionAttd, addJambDatils, ndResult, parentGuardian, addSchoolFee, updateFormStage } from '../models/student';
import { BaseService } from './base.service';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
})

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  
  constructor(private http: HttpClient,private base: BaseService) { }

  private baseUrl = this.base._baseUrl + '/student'



  
  addStudent(payload: addStudent): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  addExaminationObt(payload: addExaminationObt): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/examination-obtained`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addinstitutionAttd(payload: institutionAttd): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/institution-attended`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  addJambDatils(payload: addJambDatils): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/jamb-data`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addNDResult(payload: ndResult): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/nd-result`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  // addPayment(payload: addPayment): Observable<any> {
  //   return this.http.post<any>(this.baseUrl +
  //     `/addPayment`, payload)
  //     .pipe(
  //       catchError(err => this.base.errorHandler(err))
  //     )
  // }

  addParentGuardian(payload: parentGuardian): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/parent-guardian`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addSchoolFee(payload: addSchoolFee): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/school-fee-payment`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  listStaff(): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/list-staff`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }
  
  checkStudentAdmissiomStatus(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/admitted-student?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  checkSession(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/conrfim-session`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  // ******************  DELETE ******************
  deleteNDResult(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete-nd-result?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  deleteExaminationObtain(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/examination-obtained?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteInstitutionAttended(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/institution-attended?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  deleteJamb(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/jamb?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  deleteParentGuardian(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/parent-quardian?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteSchoolFee(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/school-fee-payment?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  // ****************** GET **********************

  verifyPaymentByEmail(email: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-payment-by-email?email=${email}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getRegStage(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-reg-stage?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCourseListsFormDetailsByDeptIdProgSemester(academicyear: string, deptid: number, level: string, programme: string, semester: string) {
    return this.http.get<any>(this.baseUrl +
      `/courseform?academicyear=${academicyear}&deptid=${deptid}&level=${level}&programme=${programme}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getExamObtained(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/examination-obtained?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getInstitutionAttend(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/institution-attended?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getJamb(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/jamb?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getNDResult(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/nd-result?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getParentGuardian(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/parent-quardian?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getSchoolFee(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/school-fee-payment?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getStudentDetail(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/students-details?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getPaymentRecord(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-student-payment-record?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  // getPaymentHistory(studentid: number): Observable<any> {  
  //   return this.http.get<any>(this.baseUrl +
  //     `/list/school-fee-payment?studentid=${studentid}`)
  //     .pipe(
  //       catchError(err => this.base.errorHandler(err))
  //     )
  // }

  entranceLogin(username: string, password: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/log-cont-student?password=${password}&username=${username}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }


  pinvalidator(email: string, pin: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/pinvalidator?email=${email}&pin=${pin}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  updatePassword(payload: resetPassword): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/reset-password`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  updateStage(payload: updateFormStage): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/update-reg-stage`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  verifyEmail(email: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/verify-email?email=${email}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  validateForm(email: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/validate-form?email=${email}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
