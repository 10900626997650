<mat-drawer-container class="mat-container" autosize>
    <mat-drawer #drawer [ngClass]="{'sidenav':showFiller == true, 'sidenavs':showFiller == false}" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

        <mat-toolbar class="navBar2">
            <div *ngIf="showFiller">
                <img class="logo" src="../assets/image/logo/SAF_Logo_Only.png">
            </div>
            <div *ngIf="!showFiller">
                <img class="small_logo" src="../assets/image/logo/SAF_Logo_Only.png">
            </div>
        </mat-toolbar>

        <mat-nav-list class="nav-scroll custom-scroll-bar">
            <a mat-list-item #menu class="menu" [routerLink]="menu.route" matTooltip="{{menu.tip}}" matTooltipPosition="right" routerLinkActive="router-link-active" *ngFor="let menu of menuList">
                <mat-icon class="menu_icons">{{menu.icon}}</mat-icon>
                <span [ngClass]="{'menuName':showFiller == true || showFiller == false && (isHandset$ | async),  'menuNames':showFiller == false && !(isHandset$ | async)  }">{{menu.name}}
                    </span>
            </a>
        </mat-nav-list>
    </mat-drawer>

    <div class="sidenav-content">
        <mat-toolbar class="navBar">
            <button type="button" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
                <mat-icon>menu</mat-icon>
            </button>
            <div *ngIf="!(isHandset$ | async)" class="minmax" mat-icon-button (click)="show()">
                <span style="position: relative; top: 5px;">
                    <mat-icon>zoom_out_map</mat-icon>
                </span>
            </div>
            <span style="margin-left: 10px;"> {{activeComponent}} </span>
            <span class="spacer"></span>

            <div class="chip my-primary-light">
                <img class="placeHold" [src]="imagePreview" />
                <div style="display: inline-block; padding-right: 10px; text-align: center; margin-top: -1px; margin-bottom: -10px;">
                    <span class="name"> {{fullname}} </span>
                    <br>
                    <p class="praa">Student</p>
                </div>
                <button mat-icon-button [mat-menu-trigger-for]="menu" class="myPrimary">
                    <mat-icon class="secondary">expand_more</mat-icon>
                </button>
                <mat-menu #menu='matMenu'>
                    <button mat-menu-item (click)="gotoProfile()">
                        <mat-icon>person</mat-icon>
                        &nbsp;
                        <span>Profile Settings</span>
                    </button>
                    <mat-divider></mat-divider>

                    <button mat-menu-item (click)="logout()">
                        <mat-icon style="color: red;">exit_to_app</mat-icon>
                        &nbsp;
                        <span>Logout</span>
                    </button>
                </mat-menu>

            </div>
        </mat-toolbar>

        <div (click)="closeSidenav()">
            <router-outlet></router-outlet>
        </div>
    </div>

</mat-drawer-container>