import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addAdmin, resetPassword } from '../models/admin';
import { changePassword } from '../models/entrance';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  baseUrl : string = this.base._baseUrl + '/admin'

  constructor(private http: HttpClient, private base : BaseService) { }
  getCourseByProgLevelSemester(level:string, programme: string, semester: string){
    return this.http.get<any>(this.baseUrl + 
      `/getcourses-by-programsemester-level?level=${level}&programme=${programme}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getCourseByProgSemester(programme: string, semester: string){
    return this.http.get<any>(this.baseUrl +
      `/getcourses-by-programsemester?programme=${programme}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getAvailableDepartment(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-available-course`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  entranceLogin(username: string, password: any): Observable<any>{
    return this.http.get<any>(this.baseUrl + 
      `/login?password=${password}&username=${username}`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

// *************** OMOOWOSTORE ***********
   addAdmin(payload: addAdmin): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  updateAdmin(payload: addAdmin): Observable<any> {
    return this.http.put<any>(this.baseUrl +
      `/update`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  resetPassword(payload: resetPassword): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/reset-password`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }  


  changePassword(payload: changePassword): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/password-reset`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  // LogAdmin(username: string, password: string): Observable<any> {
  //   return this.http.get<any>(this.baseUrl +
  //     `/log?password=${password}&username=${username}`)
  //     .pipe(
  //       catchError(err => this.base.errorHandler(err))
  //     )
  // }

  logUser(username: string, password: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/log-user?password=${password}&username=${username}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-users`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getAdminUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-admin-users`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
   
  getStaffUsers(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-only-users`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getAdminByAppId(appId: string): Observable<any> {  // TODO:  api not correct
    return this.http.get<any>(this.baseUrl +
      `/getadmin-byid?id=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  deleteUser(appId: number): Observable<any> {  // TODO:  api not correct
    return this.http.delete<any>(this.baseUrl +
      `/delete/user?id=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
