import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseAppComponent } from './core-app/base-app/base-app.component';
import { AuthGuard } from './guard/auth.guard';

// CoreModule

const routes: Routes = [
  {
    path: '', loadChildren: () => import('../app/core/core.module').then(m => m.CoreModule),
  },
  {
    path:'app', component:BaseAppComponent,
    children:[
     { path:'', loadChildren: () => import('./core-app/core-app.module').then(m => m.CoreAppModule)}
    ],
    // canActivate:[AuthGuard]
  },
  // {
  //   path:'app/market', component:MarketComponent,
  //   // canActivate:[AuthGuard]
  // },
  // {
  //   path: 'not-found' , component: NotFoundComponent
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
