import { HelperService } from './helper/helper.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { Constants, MessageUtil } from './helper/messages';
import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AdminService } from './api/admin.service';
import { SettingService } from './api/setting.service';
import { DashboardService } from './api/dashboard.service';
import { StaffService } from './api/staff.service';
import { ExaminationService } from './api/examination.service';
import { FileManagerService } from './api/file-manager.service';
import { ResultService } from './api/result.service';
import { StudentService } from './api/student.service';
import { CourseRegService } from './api/course-reg.service';
import { StudentRegService } from './api/student-reg.service';
// import { ConnectionService } from 'ngx-connection-service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private componentTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Overview');
  private isLoggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  loginUser: any;  
  isConnected: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  constructor(
    //API
    public adminService: AdminService,
    public helperService: HelperService,
    public settingService : SettingService,
    public dashboardService : DashboardService,
    public staffService : StaffService,
    public examinationService : ExaminationService,
    public resultService : ResultService,
    public fileService : FileManagerService,
    public studentService : StudentService,
    public courseRegService: CourseRegService,
    public studentRegService : StudentRegService,

    // Dependencies
    public _snackBar: MatSnackBar,    
    private _router: Router,
    // public connectionService: ConnectionService,
  ) {

    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    //   if (this.isConnected) {

    //     this.snackBar.open("You are now connected", 'Dismiss',  {
    //       duration: MessageUtil.TIMEOUT_DURATION
    //     });        
    //   }
    //   else {
    //     this.snackBar.open("You are offline, check your network settings", 'Close', {
    //       duration: MessageUtil.TIMEOUT_DURATION
    //     });

    //   }
    // })

    // this.checkLoginStatus()
  }

  openSnack(message){
    this._snackBar.open(message, "Dismiss", {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: +MessageUtil.TIMEOUT_DURATION,
    })
  }

  setLoginStatus(): void {
    this.isLoggedin.next(true);
  }

  loginStatus(): Observable<boolean> {
    return this.isLoggedin.asObservable();
  }


  titleBase():Observable<string>{
    return this.componentTitle.asObservable()
  }

  changeTitle(val:string){
    this.componentTitle.next(val)
  }

  checkLoginStatus() {
    let isLogin: any = this.helperService.getFromStore(Constants.LOGIN_USER)

    if (isLogin !== null) {
      this.isLoggedin.next(true)
    }
    else {
      this.isLoggedin.next(false)
    }
  }


  logout(key: string=Constants.LOGIN_USER): void {
    localStorage.removeItem(key)
    localStorage.clear()
    this.isLoggedin.next(false)
    this._router.navigate(["/"])
  }

  setIsLogging() {
    this.isLoggedin.next(true)
  }
}
