import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {NgxPrintModule} from 'ngx-print';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    // HighchartsChartModule,
    NgxPrintModule
    // RouterModule.forRoot(appRoutes)
  ],

  // providers:  [HttpClientService,  { provide: NgProgress }],
  //providers:  [NgxPrintModule],

  bootstrap: [AppComponent]
})
export class AppModule { }
