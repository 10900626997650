import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
})

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  constructor(private http: HttpClient, private base: BaseService) { }

  private baseUrl = this.base._baseUrl
  

  previewImage(id:number, category: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/preview/photo?category=${category}&id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  previewImage64(id:number, category: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/preview/photograph?category=${category}&id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

   imageUpload(id:number, category: string, file : any):Observable<any> {
    return this.http.post<any>(this.baseUrl + 
      `/upload/photograph?category=${category}&id=${id}`,file )
    .pipe(
      catchError( err =>  this.base.errorHandler(err))
    )
  } 

  downloadStudentsExaminationClearance(level: string, semester: string, session: string, studentid: number): Observable<any> {
      return this.http.get<any>(this.baseUrl +
        `/download/clearance?level=${level}&semester=${semester}&session=${session}&studentid=${studentid}`,
        { headers: headers, responseType: 'blob' as 'json' }
      )
        .pipe(
          catchError(err => this.base.errorHandler(err))
        )
    }

    downloadStudentsIdentityCardInfo(studentid: number): Observable<any> {
      return this.http.get<any>(this.baseUrl +
        `/download/card-clearance?studentid=${studentid}`,
        { headers: headers, responseType: 'blob' as 'json' }
      )
        .pipe(
          catchError(err => this.base.errorHandler(err))
        )
    }

    downloadStudentsResults(matricNumber:any,program:any,semester:any,session:any): Observable<any> {
      return this.http.get<any>(this.baseUrl +
        `/download/result?matricNumber=${matricNumber}&program=${program}&seme=${semester}&session=${session}`,
        { headers: headers, responseType: 'blob' as 'json' }
      )
        .pipe(
          catchError(err => this.base.errorHandler(err))
        )
    }
 
}
