import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  baseUrl : string = this.base._baseUrl + 'settings'

  constructor(private http: HttpClient, private base : BaseService) { }
  // 

  uploadPhoto(appid, file): Observable<any>{
    return this.http.post<any>(this.baseUrl +
      `/image/upload?appid=${appid}`,file)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  uploadPhotograph(appid, file): Observable<any>{
    return this.http.post<any>(this.baseUrl +
      `/upload/photograph?appid=${appid}`,file)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

   previewImage(appid): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/preview/photo?appid=${appid}`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  previewPhoto(appid): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/preview/photograph?appid=${appid}`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  
}
