import { Constants } from './messages';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  saveToStore(key : string, data : any){
    localStorage.setItem(key, JSON.stringify(data))
  }

  removeItem(key : string){
    localStorage.removeItem(key)
  }

  getUserAppId(){
    let rawid = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let userId = rawid.id
    return (userId)

  }
  public getFullname(){
    let usern = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let user = usern.fullname
    return user
  }
  public getEmail(){
    let usern = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    return usern.email
     
  }
  public getPhoneNo(){
    let usern = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    return usern.phone
     
  }

  public getMatricNo(){
    let usern = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    return usern.matricnumber
  }
  public getProgramme(){
    let usern = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    return usern.programme     
  }

  getFromStore(key : string=Constants.LOGIN_USER){
    return JSON.parse(localStorage.getItem(key))
  }
}
