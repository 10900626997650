import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { updateSessionReturningStd } from '../models/student';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class StudentRegService {
 
  constructor(private http: HttpClient,private base: BaseService) { }

  private baseUrl = this.base._baseUrl + '/student-reg'

  
  getEnterpreneur(): Observable<any> {  
    return this.http.get<any>(this.baseUrl +
      `/get-enterpreneuship`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  updateReturningStudent(payload: updateSessionReturningStd): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/registration`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  VerifyRegisterationPin(email: string, pin: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/verify?email=${email}&pin=${pin}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  VerifyResultPin(email: string, pin: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/result-checker-pin?email=${email}&pin=${pin}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  checkPinRegStatus(email: string): Observable<any> {  
    return this.http.get<any>(this.baseUrl +
      `/pin-reg-status?email=${email}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  ResultPinChecker(pin: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/result-checker-pin?pin=${pin}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
