import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {


  public _baseUrl: string = 'https://api.safpoly.org'
  //public _baseUrl: string ='http://localhost:8080/catalog';
  
  
  constructor() { }

  errorHandler(error:HttpErrorResponse){
    return Observable.throw(error || "Server Error") 
  } 

}
