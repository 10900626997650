import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { changePassword } from '../models/entrance';
import { createLecturerCourse } from '../models/staff';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  
  constructor(private http: HttpClient,private base: BaseService) { }

  private baseUrl = this.base._baseUrl + '/lecturer'

  
  createLecturerCourse(payload: createLecturerCourse): Observable<any>{
    return this.http.post<any>(this.baseUrl +
      `/create`, payload)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  changePassword(payload: changePassword): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/change-password`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getLecturersByStudents(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-lecturer-by-student`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getLecturersbyAdmissionYear(admissionYear: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-lecturers?admissionyear=${admissionYear}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getLecturersOnlybyAdmissionYear(admissionYear: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-lecturesonly?admissionyear=${admissionYear}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getLecturersDetailsbyLecurerId(lecturer: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-lecturere-courses-details?lecturer=${lecturer}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getStudentByLectIdProgLvlSemesterDeptId(admissionyear: string, lecturer: number, programme: string,semester : string, level: string, deptid: number ): Observable<any> {
    return this.http.get<any>(this.baseUrl +           
      `/get-lecturer-by-student?admissionyear=${admissionyear}&deptid=${deptid}&lecturer=${lecturer}&level=${level}&programme=${programme}&semester=${semester}`) //&deptid=${deptid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getLecturerByAppId(appId: number): Observable<any> { 
    return this.http.get<any>(this.baseUrl +
      `/get-lecturers-by-id?id=${appId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
}
