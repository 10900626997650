import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  
  constructor(private http: HttpClient,private base: BaseService) { }

  private baseUrl = this.base._baseUrl + '/result'

  
  getResultByDeptProgramsemesterSession(dept: number, program: string, semester: string, session: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/by-dept?dept=${dept}&program=${program}&semester=${semester}&session=${session}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  checkResult(email: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/pin-status?email=${email}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getResultByMatricnoProgramSession(matricno: string, program: string, session: string, semester: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/by-matric?matricNumber=${matricno}&program=${program}&session=${session}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  VerifyRegisterationPin(email: string, pin: any): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/verify-pin?email=${email}&pin=${pin}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
}

